<template>
  <div>
    <md-tabs>
      <md-tab id="tab-all" md-label="All">
        <md-card-content>
          <form v-on:submit.prevent="validateData">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <md-card-header>
                <div class="md-title">Notification</div>
              </md-card-header>

              <md-card-content>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field>
                      <label for="option">Select Option</label>
                      <md-select v-model="option" name="option" id="option" :disabled="sending" required>
                        <md-option value="Client">Customers</md-option>
                        <md-option value="Broom">Broomers</md-option>
                      </md-select>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field>
                      <label for="title">Select Title</label>
                      <md-input name="title" id="title" v-model="title" :disabled="sending" required/>
                    </md-field>
                    <md-field>
                      <label for="msg">Message</label>
                      <md-textarea name="msg" id="msg" v-model="message" :disabled="sending" required></md-textarea>
                    </md-field>
                  </div>
                </div>
              </md-card-content>

              <md-progress-bar md-mode="indeterminate" v-if="sending"/>

              <md-card-actions>
                <button type="submit" class="btn md-primary" :disabled="sending">Send</button>
              </md-card-actions>
            </div>
          </form>
        </md-card-content>
      </md-tab>
      <md-tab id="tab-customer" md-label="Customers" @click="getAllUser('C')">
        <md-card-content>
          <form v-on:submit.prevent="validateUser">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <md-card-header>
                <div class="md-title">Notification</div>
              </md-card-header>

              <md-card-content>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field>
                      <label for="option">Select Customers</label>
                      <md-select v-model="client" name="client" id="client" :disabled="sending" multiple required>
                        <md-option :value="item.fcmToken" v-for="item in dataClient"> {{ item.name }} - {{
                            item.phone
                          }}
                        </md-option>
                      </md-select>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field>
                      <label for="title">Select Title</label>
                      <md-input name="title" id="title" v-model="title" :disabled="sending" required/>
                    </md-field>
                    <md-field>
                      <label for="msg">Message</label>
                      <md-textarea name="msg" id="msg" v-model="message" :disabled="sending" required></md-textarea>
                    </md-field>
                  </div>
                </div>
              </md-card-content>

              <md-progress-bar md-mode="indeterminate" v-if="sending"/>

              <md-card-actions>
                <button type="submit" class="btn md-primary" :disabled="sending">Send</button>
              </md-card-actions>
            </div>
          </form>
        </md-card-content>
      </md-tab>
      <md-tab id="tab-broomer" md-label="Broomers" @click="getAllUser('B')">
        <md-card-content>
          <form v-on:submit.prevent="validateBroomer">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <md-card-header>
                <div class="md-title">Notification</div>
              </md-card-header>

              <md-card-content>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field>
                      <label for="option">Select Broomers</label>
                      <md-select v-model="broom" name="broom" id="broom" :disabled="sending" multiple required>
                        <md-option :value="item.fcmToken" v-for="item in dataClient"> {{ item.name }} - {{
                            item.phone
                          }}
                        </md-option>
                      </md-select>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field>
                      <label for="title">Select Title</label>
                      <md-input name="title" id="title" v-model="title" :disabled="sending" required/>
                    </md-field>
                    <md-field>
                      <label for="msg">Message</label>
                      <md-textarea name="msg" id="msg" v-model="message" :disabled="sending" required></md-textarea>
                    </md-field>
                  </div>
                </div>
              </md-card-content>

              <md-progress-bar md-mode="indeterminate" v-if="sending"/>

              <md-card-actions>
                <button type="submit" class="btn md-primary" :disabled="sending">Send</button>
              </md-card-actions>
            </div>
          </form>
        </md-card-content>
      </md-tab>
      <md-snackbar :md-active.sync="showSnackMessage">
        <span>{{ snackMessage }}</span>
      </md-snackbar>
    </md-tabs>
  </div>
</template>
<script>
import {listCustomer, sendNotification, sendNotificationUser} from "@/services/notification";

export default {
  components: {},

  data: () => ({
    sending: false,
    title: null,
    option: 'Client',
    message: null,
    showSnackMessage: false,
    snackMessage: null,
    dataClient: [],
    client: null,
    broom: null,
  }),
  methods: {
    validateData() {
      let dataSend = {
        title: this.title,
        option: this.option,
        message: this.message,
      };
      if (dataSend) {
        this.sendNotify(dataSend);
      }
    },

    showMsg() {
      this.sending = true

      // Instead of this timeout, here you can call your API
      window.setTimeout(() => {
        this.sending = false;
        this.showSnackMessage = true;
        this.snackMessage = 'Success!';
        this.clearForm();
      }, 1500)
    },

    clearForm() {
      this.title = null
      this.option = 'Client'
      this.message = null
    },

    getAllUser(item) {
      listCustomer(item).then(res => {
        if (res.data.length > 0) {
          this.dataClient = res.data;
        } else {
          this.showSnackMessage = true;
          this.snackMessage = 'Not list';
        }
      });
    },

    validateUser() {

      let dataSend;

      if (this.client.length > 0) {
        dataSend = {
          title: this.title,
          option: this.client,
          message: this.message,
          api: 'C'
        };

        if (dataSend.option.length > 0) {
          this.sendNotify(dataSend);
        } else {
          this.showSnackMessage = true;
          this.snackMessage = 'Select Option';
        }
      }
    },

    validateBroomer() {

      let dataBroom;

      if (this.broom.length > 0) {
        dataBroom = {
          title: this.title,
          option: this.broom,
          message: this.message,
          api: 'B'
        };

        if (dataBroom.option.length > 0) {
          this.sendNotify(dataBroom);
        } else {
          this.showSnackMessage = true;
          this.snackMessage = 'Select Option';
        }
      }
    },

    sendNotify(data) {
      sendNotificationUser(data).then(res => {
        if (res.data === true) {
          this.showMsg();
        } else {
          this.showSnackMessage = true;
          this.snackMessage = 'Not send Notification';
        }
      });
    }

  },
}
</script>
