import {getHeaders, handleLogout} from "@/services/auth";

const axios = require('axios');

const API = `${process.env.VUE_APP_BROOM_API_ENDPOINT}`;

export const sendNotification = async (data) => {
  try {
    return await axios.post(API + 'sendNotification', data, {
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}

export const listCustomer = async (data) => {
  try {
    return await axios.get(API + 'listUser' + data, {
      headers: getHeaders()
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendNotificationUser = async (data) => {
  try {
    return await axios.post(API + 'sendNotificationUser', data, {
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}
